import $ from 'jquery';  
window.jQuery = $; window.$ = $;


var apu = (function() {   
   
  return {
    update_load_progress: function() {      
      if (this.buffered.length > 0) {
        jQuery(this).data('playable').css({ width: parseInt((this.buffered.end(0) / this.duration) * 100, 10) + "%" } );
      }  
    },
    
    formated_time: function(secs) {
      if (secs > 0) {
        var pad = '00';
        var m = '' + Math.floor(secs / 60);
        var s = '' + Math.floor(secs % 60);  
        return pad.substring(0, pad.length - m.length) + m + ':' + pad.substring(0, pad.length - s.length) + s;
      } else {
        return '00:00';
      }  
    },
    
    time_update: function() {  
      var $this = jQuery(this);
          
      if (!$this.data('scrubbing')) {
        $this.data('current').html(apu.formated_time(this.currentTime)); 
        apu.position_playhead(this);
      }
    },
    
    position_playhead: function(_this) {
      var $this = $(_this),
              playhead = $this.data('playhead'),
              timeline = $this.data('timeline'),
              images = $this.data('images'),
              procentage_played = (_this.currentTime / _this.duration)*100;
      
              if (!isNaN(procentage_played)) {
        //bw_img.clipPath([[procentage_played,0],[100,0],[100,100],[procentage_played,100]],{isPercentage: true});
        jQuery(images.get(0)).css({width: procentage_played + '%'})
        jQuery(images.get(1)).css({left: procentage_played + '%'})
        playhead.css({left: procentage_played + '%'})                
        // playhead.css({ left: ((_this.currentTime / _this.duration) * (timeline.width()-playhead.outerWidth())) + "px" } );
      }
    }
    
    
  };  
})( jQuery );

(function( $ ){

   $.fn.audio_player = function() {
     return this.each(function(){
       var $this = $(this);
        
       var audio = $('audio', $this);
        
       audio.data('playable', $('.playable', $this))
       audio.data('playhead', $('.playhead', $this))
       audio.data('timeline', $('.timeline', $this))
       audio.data('current', $('.current', $this))
       audio.data('scrubbing', false)
       audio.data('images', $('.img', $this))

       var btn = $(".toggle-play", $this);
       var replay = $(".replay", $this);
       var duration =  $('.duration', $this);
      
       // Can play
       audio[0].addEventListener("canplay", function() {
         duration.html(apu.formated_time(this.duration))
         btn.removeClass('not-ready').parent().addClass('canplay');
       });
        
       // Loading
       audio[0].addEventListener('progress',       apu.update_load_progress);
       audio[0].addEventListener('loadeddata',     apu.update_load_progress);
       audio[0].addEventListener('canplaythrough', apu.update_load_progress);
       audio[0].addEventListener('playing',        apu.update_load_progress);       
       
       // Time update
       audio[0].addEventListener("timeupdate", apu.time_update);
       
       // Play / pause
       btn.on('click', function(e){
         $this = $(this);  
         
         if (audio[0].readyState == 0) {
           $this.addClass('not-ready');
           audio[0].load();
         }
         
         if (audio[0].paused) {
           audio[0].play();
           $this.removeClass('paused').parent().addClass('playing');
           $this.parent().siblings('.playing').find('.toggle-play:not(.paused)').trigger('click');
         } else {
           audio[0].pause();
           $this.addClass('paused').parent().removeClass('playing');

         }
       });
       
       // Replay
       replay.on('click', function(e){
         audio[0].currentTime = 0;
         btn.trigger('click');
         //$this.parent().siblings('.playing').find('.toggle-play:not(.paused)')
         //audio[0].play();
         //btn.removeClass('paused').parent().addClass('playing')
       });
       
       // Done
       audio[0].addEventListener("ended", function() {
         btn.addClass('paused');
       });
              
       // Click on timeline
       audio.data('playable').on('click', function(e){
         var x = e.pageX - $(this).offset().left  
         audio[0].currentTime = audio[0].duration * (x / audio.data('timeline').width());
       });
       
       // Scrubbing
       // audio.data('playhead').draggable({ 
       //   axis: "x", 
       //   containment: audio.data('playable'),
       //   start: function(e, ui) {
       //     audio.data('scrubbing', true);
       //   },
       //   drag: function(e, ui) {
       //     audio.data('current').html(apu.formated_time(audio[0].duration * (ui.position.left / audio.data('timeline').width()))) 
       //   },
       //   stop: function(e, ui) {
       //     audio.data('scrubbing', false);
       //     audio[0].currentTime = audio[0].duration * (ui.position.left / audio.data('timeline').width());
       //   }
       // });
                  
     });
   };
       

})( jQuery );


jQuery(function(){
  jQuery('div.audioguide').audio_player();
});