const DELAY = 600
const MARGIN = 10
const SPACER = 4

var timer = null
var open_tooltip = null

let showTooltip = function(e) {
  
  let link = e.target
  
  if (timer != undefined) {
    clearTimeout(timer); 
  }
  
  if ( open_tooltip != undefined) {
    open_tooltip.remove()          
    open_tooltip = null
  }



  timer = setTimeout(function() {                
            
    let x = 0
    let y = 0    
            
    let tooltip = document.createElement('div')
    tooltip.classList.add('tooltip')
  
    tooltip.innerHTML = link.dataset.tooltip
    document.querySelector('body').appendChild(tooltip)   
    
    let rect = link.getBoundingClientRect()

    x = rect.x + (rect.width / 2) - (tooltip.offsetWidth / 2) + window.scrollX
    y = rect.y + rect.height + SPACER + window.scrollY
    
    // Over or under
    if ((y + tooltip.offsetHeight) > (window.scrollY + window.innerHeight)) {
      y = (rect.y + window.scrollY) - tooltip.offsetHeight - SPACER        
    } 
    
    // Right or left        
    if ((x + tooltip.offsetWidth) >= window.innerWidth) {
      x = window.innerWidth - tooltip.offsetWidth - MARGIN
    } else if (x < 0) {
      x = MARGIN
    }
  
    tooltip.style.left = x + 'px'
    tooltip.style.top = y + 'px' 
  
    open_tooltip = tooltip
  
  }, DELAY)      
  
}


let hideTooltip = function(e){
  
  if (timer != undefined) {
    clearTimeout(timer); 
    timer = null
  }
  
  if ( open_tooltip != undefined) {
    open_tooltip.remove()          
    open_tooltip = null
  } 
}


function Tooltip(selector) {
  
  document.querySelectorAll(selector).forEach((node) => {        
    node.addEventListener('mouseenter', showTooltip, false)    
    node.addEventListener('focus', showTooltip, false)    
    
    node.addEventListener('click', hideTooltip, false);          
    node.addEventListener('mouseleave', hideTooltip, false);          
    node.addEventListener('blur', hideTooltip, false)    
  })
}

export default Tooltip;