import $ from 'jquery';  
window.jQuery = $; window.$ = $;

import Swiper from 'swiper/bundle';  
import Cookies from 'js-cookie';

import './src/audioguides';
import './src/audioguides';

import Tooltip from './src/tooltip';



$(function() {  

  // All external links - new tab
  $('a')
  	.filter('[href^="http"], [href^="//"]')
  	.not('[href*="' + window.location.host + '"]')
  	.attr('rel', 'noopener noreferrer')
  	.attr('target', '_blank');    
    
  $('.swiper').each(function(){
    if ($('.swiper-slide', $(this)).length > 1) {
      var swiper = new Swiper(this, {
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        autoplay: {
          delay: 5000,
        },
      });          
    }          
  });
  
  var t = setTimeout(function(){    
    $('meta[name=thm-msg]').each(function(){
      var $this = $(this);  
      $.ajax({
        type: "GET",
        url: $this.attr('href'),
        dataType: 'script'
      }).done(function(){    
        $(document).on('click', '.popup-msg button', function(e){
          var $this = $(this);          
          Cookies.set($this.data('name'), $this.data('value'), { expires: 30 });
          
          if (($this.data('value') == '1')) {
            gtag('consent', 'update', {'analytics_storage': 'granted'});
          }
          
          $(".popup-msg").remove();          
          e.preventDefault();
        });        
      });          
    })    
  }, 600);       
  
  $("#menu a, #search input, #search label").each(function(){
    $(this).attr('tabindex', -1)
  })

  const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')

  $('video').each(function(){
    var $this = $(this)
    var video = $this.get(0)
    var btn = $this.siblings('.video-ctrl')

    

  
    
    video.addEventListener('playing', function(){
      $this.removeClass('paused')
    })

    video.addEventListener('pause', function(){
      $this.addClass('paused')
    })

    btn.on('click', function(e){
      if (video.paused) {
        video.play()
      } else {
        video.pause()
      }
    })

    
    if (mediaQuery.matches) {
      video.pause()
      $this.removeClass('playing').addClass('paused')
    }

  })


  // Tooltips
  Tooltip('[data-tooltip]')


});


$(document).on('click', "header a.m", function(e){  
  $('header').removeClass('open-s').toggleClass('open-m');                           
  
  let is_open = $('header').hasClass('open-m') ? true : false  
  
  $("#search input, #search label").each(function(){
    $(this).attr('tabindex', -1)
  })

  $("#menu a").each(function(){
    is_open ? $(this).removeAttr('tabindex') : $(this).attr('tabindex', -1)
  })
  
  e.preventDefault();
});

$(document).on('click', "header a.s", function(e){  
  $('header').removeClass('open-m').toggleClass('open-s');                           

  let is_open = $('header').hasClass('open-s') ? true : false  
  $("#menu a").each(function(){
    $(this).attr('tabindex', -1)
  })
  $("#search input, #search label").each(function(){
    is_open ? $(this).removeAttr('tabindex') : $(this).attr('tabindex', -1)
  })

  if (is_open) {
    $(this).next().attr('tabindex', 1)
  } else {
    $(this).next().removeAttr('tabindex')
  }

  e.preventDefault();
});





/* Work */  
$(document).on('click', '.tmpl-work .artists>button', function(e){
  var btn = $(this);
  btn.parent().toggleClass('open')
  btn.html(btn.parent().hasClass('open') ? '[<span>-</span>]' : '[<span>+</span>]')
});

$(document).on('click', 'a.enlarge', function(e){
  var l = $(this);
  window.open(l.attr('href'), l.data('id'), 'toolbar=0,scrollbars=1,location=0,status=0,menubar=0,resizable=1,width=732,height=800');
  e.preventDefault();
});

$(document).on('click', '.tmpl-work button.close', function(e){
  $(this).parent().parent().parent().hide().addClass('hidden');    
});

/* Open work */
$(document).on('click', '.works>a, .results>a', function(e){
  var l = $(this), 
      invno = l.data('id'), 
      c = $("#"+invno);
    
  // Open
  if (c.length == 0) {
    $.ajax({
      url: l.attr('href'),
      beforeSend: function( xhr ) {        
        try { ga('send', 'pageview', l.attr('href')) } catch(err) {};
      
        c = $('<div><div class="loading">'+l.parent().data('loading')+'</div></div>')
              .insertAfter(l)
              .addClass('tmpl-work')            
              .attr('id', invno);        
      }
    })
      .done(function( html ) {        
        c.replaceWith($(html).filter('.tmpl-work'));
        
        $('html, body').animate({
          scrollTop: $("#"+invno).prev().offset().top - 6
        }, 400);                
      })
      .fail(function(){
        $('.loading', c).html(l.parent().data('fail'))
      });
    
  // Close  
  } else {
    if (c.hasClass('hidden')) {
      c.show().removeClass('hidden');
      $('html, body').animate({
        scrollTop: c.prev().offset().top - 6
      }, 400);                
      
    } else {
      c.hide().addClass('hidden');
    }
  }
  e.preventDefault();
});


// Search works
$(document).on('change', "#category-select", function(){
  var f = $('#search_works'), v = $(this).val();
  f.attr('action', v);
  f.submit();
});

$(document).on('change', "#public_domain", function(){
  $('#search_works').submit();
});


$(document).on('submit', "form.subscribe", function(e){

  var f = $(this).addClass('sending');
    
  $.ajax({
    method: 'POST',
    url: f.attr('action'),
    data: f.serialize(),
    dataType: 'script'
  }).done(function(){    
    
  });
  
  e.preventDefault();  
});